import React, {useRef} from 'react'
import emailjs from '@emailjs/browser'
import './Contact.css'
import Header from './Header'
import Content2 from './Content2'
import TitleMenu from './TitleMenu'
import SocialMediaLogos from './SocialMediaLogos'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(null);

    emailjs.sendForm('service_cqipbkc', 'template_s0urw9h', form.current, '2sYhZZo9j6syvNXLm')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };

  return (
    <div>
      <TitleMenu />
      <Header />
    <div className='main'>
    <div className='left'>
    <h4>Just drop the mail. Our Team will contact you !</h4>
    <form ref={form} onSubmit={sendEmail}>
    <label>Enquiry for:</label>
    <select name="options">
        <option value="Power BI">Power BI</option>
        <option value="Web Designing">Web Designing</option>
        <option value="Java Application">Java Application</option>
    </select>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />      
      <label>Contact No</label>
     <input type="number" name="contact_number" required placeholder="Enter your phone number"></input>
     <label>Message</label>
      <textarea name="message" /><br></br>
      <input type="submit" value="Send" />
    </form>
    </div>
    <div className='right'>
          <h4>Fill the enquiry Form</h4>
    </div>
    </div>
    <Content2 />
    <SocialMediaLogos />
    </div>
  );
}

export default Contact