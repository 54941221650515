import React from 'react';
import './TitleMenu.css';


const TitleMenu = () => {
  return (
    <div className="title-menu">
      <div className="left-side">
        <span>brightsoft0@gmail.com</span>
        <span> | </span>
        <span>+91 9014011892</span>
      </div>
      <div className="right-side">
        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><img src="/social/facebook.png" alt="Facebook" /></a>
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><img src="/social/Twitter.png" alt="Twitter" /></a>
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><img src="/social/LinkedIn.png" alt="LinkedIn" /></a>
        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src="/social/Instagram.png" alt="Instagram" /></a>
      </div>
    </div>
  );
};

export default TitleMenu;
