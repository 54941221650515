import React from 'react'
import './Description.css'

const Description = () => {
  return (
    <div className="container">
      <div className="box">
        <h3>Power BI Services</h3>
        <p>Create interactive dashboards and reports using Power BI Desktop. 
            Embed these dashboards into your website to provide real-time insights to your users.</p>
      </div>
      <div className="box">
        <h3>Web Designing Services</h3>
        <p>Looking to make a lasting impression with your online presence? Look no further than 
            Bright Softs. We specialize in crafting captivating and dynamic websites that resonate with 
            your audience. Let Bright Softs transform your vision into a stunning reality.</p>
      </div>
      <div className="box">
        <h3>Java Application Services</h3>
        <p>Eager to develop a robust and efficient Java application? Turn to Bright Softs. 
            Our expert team is dedicated to crafting high-performance Java applications tailored to 
            your specific needs. With Bright Softs by your side, your Java application will stand out 
            for its reliability, scalability, and innovation. Trust Bright Softs to bring your Java 
            ideas to life.</p>
      </div>
    </div>
  )
}

export default Description