import React from 'react'
import Home from './components/Home'
import { Routes, Route } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom';
import PowerBi from './components/PowerBi'
import Website from './components/Website'
import JavaProject from './components/JavaProject'
import About from './components/About'
import Contact from './components/Contact'

const App = () => {
  return (
    <Router>
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/powerbi' element={<PowerBi />} />
        <Route path='/webdesign' element={<Website />} />
        <Route path='/javaproject' element={<JavaProject />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </div>
    </Router>
  )
}

export default App