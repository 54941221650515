import React from 'react';
import './SocialMediaLogos.css';

const SocialMediaLogos = () => {
  return (
    <div className="social-media-logos">
      <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><img src="/social/facebook.png" alt="Facebook" /></a>
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><img src="/social/Twitter.png" alt="Twitter" /></a>
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><img src="/social/LinkedIn.png" alt="LinkedIn" /></a>
        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src="/social/Instagram.png" alt="Instagram" /></a>
    </div>
  );
};

export default SocialMediaLogos;
