import React from 'react'
import './JavaProject.css'
import Header from './Header'
import Content2 from './Content2'
import TitleMenu from './TitleMenu'
import SocialMediaLogos from './SocialMediaLogos'

export const images={
	"image1":"/images/j3.png"
}
const JavaProject = () => {
  return (
    <div>
      <TitleMenu />
      <Header />
      <h2>Java Application: <span>Java software is robust, versatile, secure, scalable, and widely adopted.</span></h2>  
    <div class="con2">
      <div>
      <h1>Empower your projects with robust capabilities, enhance productivity, 
        and ensure reliability in today's evolving tech landscape with Java applications.</h1>
      </div>
      <div>
      <img src={images.image1} alt={images.image1} />
      </div>
    </div>
    <div>
      <p>Experience unparalleled expertise with our Java development team. Each member embodies a fusion of passion 
        and proficiency, crafting solutions that stand at the forefront of innovation. Dedicated to excellence, 
        our team seamlessly integrates cutting-edge technologies, ensuring robust, scalable, and efficient applications.
         Through collaborative synergy, we navigate complexities with finesse, delivering tailor-made solutions that 
         resonate with client aspirations. Trust in our commitment; with a rich blend of experience and forward-thinking,
          we not only meet expectations but continually surpass them. Partner with us and witness the pinnacle of Java 
          development prowess.</p>
    </div>
    <Content2 />
    <SocialMediaLogos />
    </div>
  )
}

export default JavaProject