import React from 'react'
import './About.css'
import Content2 from './Content2'
import Header from './Header'
import TitleMenu from './TitleMenu'
import SocialMediaLogos from './SocialMediaLogos'

const About = () => {
  return (
    <div>
      <TitleMenu />
      <Header />
    <div className='about'>
      <h2>About</h2>
      <p>
      At Bright Softs, we are a dynamic team of freelancers passionate about transforming data into 
      actionable insights. Specializing in Power BI, we craft visually appealing and interactive 
      dashboards that empower businesses to make informed decisions swiftly and efficiently. 
      Our expertise doesn't stop at data visualization; we extend our capabilities to web development, 
      creating bespoke websites that reflect our clients' unique brand identities. Moreover, with our 
      proficiency in Java, we design and develop tailored applications that streamline processes, 
      enhance user experiences, and drive business growth. Committed to excellence, we prioritize 
      understanding our clients' needs, ensuring every solution we deliver aligns perfectly with their 
      objectives and aspirations.
    </p>
    <p>Driven by innovation and a relentless pursuit of excellence, Bright Softs stands as a beacon of 
    quality and reliability in the freelancing landscape. Our collaborative approach, combined with a 
    deep understanding of emerging technologies, positions us at the forefront of digital transformation. Whether it's elucidating complex data through intuitive dashboards, building robust websites that captivate audiences, or crafting efficient Java applications, we bring a blend of creativity and technical prowess to every project. As we continue to evolve and adapt to the ever-changing digital landscape, our unwavering commitment remains: to deliver solutions that not only meet but exceed expectations, fostering long-term partnerships built on trust and mutual success.
      </p>
    </div>
    <Content2 />
    <SocialMediaLogos />
    </div>
  )
}

export default About