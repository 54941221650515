import React from 'react'
import './Website.css'
import Content2 from './Content2'
import Header from './Header'
import TitleMenu from './TitleMenu'
import SocialMediaLogos from './SocialMediaLogos'

export const images={
	"image1":"/images/w2.jpg"
}
const Website = () => {
  return (
    <div>
      <TitleMenu />
      <Header />
      <h2>Website Designing: <span>Captivate audiences with our web design mastery.</span></h2>  
    <div class="con1">
      <div>
      <h1>We craft, design, and conceptualize your website using React JS, HTML, CSS, and JavaScript, 
        employing cutting-edge, refined, and tailored tools, ensuring top-tier satisfaction.</h1>
      </div>
      <div>
      <img src={images.image1} alt={images.image1} />
      </div>
    </div>
    <div>
      <p>Our development team is a cohesive blend of passionate professionals committed to pushing boundaries 
        and delivering excellence. With expertise spanning across various technologies like React JS, HTML, CSS, 
        and JavaScript, they transform visions into interactive digital experiences. Their collaborative spirit 
        fosters innovation, ensuring that every project is not just functional but also aesthetically pleasing. 
        Continuously adapting to the ever-evolving tech landscape, our team stays ahead of the curve, embracing 
        challenges and turning them into opportunities. Their dedication, combined with a deep understanding of 
        user-centric design, positions them at the forefront of web development, driving success and setting 
        industry benchmarks.</p>
    </div>
    <Content2 />
    <SocialMediaLogos />
    </div>
  )
}

export default Website