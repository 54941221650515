import React from 'react'
import './Content2.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const Content2 = () => {
  return (
    <div>
    <div className="container">
      <div className="content">
      At Bright Softs, we harness the trifecta of technology's finest: Power BI, Java application 
      development, and cutting-edge web design. Our expertise in Power BI transcends traditional 
      analytics, transforming raw data into actionable insights that empower informed decision-making. 
      Simultaneously, our proficiency in Java application development ensures robust, scalable, 
      and efficient software solutions tailored to diverse business needs. Complementing this, 
      our web designing team crafts dynamic interfaces that captivate audiences, ensuring seamless 
      user experiences across platforms. Together, these pillars of excellence converge, underlining 
      Bright Softs' commitment to driving digital innovation, reliability, and unmatched quality in 
      every project we undertake. Entrust us with your vision, and witness the synthesis of technology 
      and creativity redefine your digital landscape.
      </div>
      <div className="links">
        <h3>Our Services</h3>
        <Link to="/powerbi">Power BI</Link><br/><br/>
        <Link to="/webdesign">Website Design</Link><br/><br/>
        <Link to="/javaproject">Java Application</Link><br/><br/>
      </div> 
    </div>
    <div className="contact">
        <div><FontAwesomeIcon icon={faPhoneVolume} />: +91 9014011892</div>
        <div><FontAwesomeIcon icon={faEnvelope} />: brightsofts0@gmail.com</div>
        <div><FontAwesomeIcon icon={faGlobe} />: Hyderabad, Guntur, Vijayawada, Vishakapatnam</div>
    </div>
    </div>
  )
}

export default Content2