import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
 
  const toggleServicesDropdown = () => {
    setShowServicesDropdown(!showServicesDropdown);
  };

  return (
    <header className="header">
      <div className="logo"><Link to="/">Brightsofts</Link></div>

      <nav className={`nav-links ${showMobileMenu ? 'show' : ''}`}>
        <div className="nav-link" onClick={toggleServicesDropdown}>
          Services
          <div className={`dropdown-content ${showServicesDropdown ? 'show' : ''}`}>
          <Link to="/powerbi">Power BI</Link>
          <Link to="/webdesign">Website Design</Link>
           <Link to="/javaproject">Java Application</Link>
          </div>
        </div>
        <Link to="/about">About</Link>
        <Link to="/contact">ContactUs</Link>
      </nav>

      <button className="mobile-toggle-btn" onClick={toggleMobileMenu}>
        ☰
      </button>
    </header>
  );
};

export default Header;
