import React from 'react'
import './PowerBi.css'
import Header from './Header'
import Content2 from './Content2'
import SocialMediaLogos from './SocialMediaLogos'
import TitleMenu from './TitleMenu'

export const images={
	"image1":"/images/4.png"
}
const PowerBi = () => {
  return (
    <div>
      <TitleMenu />
      <Header />
      <h2>Power BI Services: <span>Elevate insights; empower decisions with our Power BI expertise.
        </span></h2>  
    <div className="con">
      <div>
      <h1>Empower your team with actionable insights, drive informed decision-making, 
      and position your business for success in today's data-driven landscape with Power BI.</h1>
      </div>
      <div>
      <img src={images.image1} alt={images.image1} />
      </div>
    </div>
    <div>
      <p>Dedicated to excellence and innovation, our Power BI team comprises seasoned professionals passionate 
        about transforming data into actionable insights. With a blend of expertise in data analysis, 
        visualization, and business intelligence, we are committed to delivering tailored solutions that empower 
        your organization. Leveraging the latest Power BI capabilities, our team collaborates closely with clients, 
        ensuring seamless integration, optimal performance, and impactful results. Trust in our Power BI team's 
        proficiency and dedication to drive your business forward, unlocking new opportunities and fostering 
        data-driven success. Partner with us and experience the difference of a truly exceptional Power BI team.</p>
    </div>
    <Content2 />
    <SocialMediaLogos />
    </div>
  )
}

export default PowerBi