import React from 'react'
import Header from './Header'
import ImagesDisplay from './ImagesDisplay'
import Description from './Description'
import Content2 from './Content2'
import TitleMenu from './TitleMenu'
import SocialMediaLogos from './SocialMediaLogos'

const Home = () => {
  return (
    <div>
      <TitleMenu />
      <Header />
      <div style={styles.main}>Welcome to Bright Softs</div>
      <ImagesDisplay />
      <Description />
      <Content2 />
      <SocialMediaLogos />
    </div>
  )
}

const styles = {
  main: {
    color: 'orange',
    textAlign: 'center',
    fontSize: '30px',
    margin: '10px'
  }
};

export default Home