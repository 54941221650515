// ImageCarousel.js

import React, { useState } from 'react';
import './DisplayImages.css'


const images = [
    "/images/1.jpg",
	"/images/4.png",
	"/images/7.png",
    "/images/j3.png",
    "/images/w1.jpg",
    "/images/w2.jpg"
];

const ImagesDisplay = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel-container">
      <button onClick={prevImage} className="nav-btn">Prev</button>
      <img src={images[currentIndex]} alt={`${currentIndex}`} className="carousel-image" />
      <button onClick={nextImage} className="nav-btn">Next</button>
    </div>
  );
};

export default ImagesDisplay;
